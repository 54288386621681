



























import { Vue, Component, Prop } from 'vue-property-decorator'
import {BButton, BCard, BCol, BFormGroup, BFormInput, BFormSelect, BRow, BTab, BTabs} from "bootstrap-vue";

@Component({
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect
  }
})
export default class RegistrationMaintenanceReindex extends Vue {
  registrationId: string = null;

  async submit() {
    const req = await this.$api.registrations.maintenance.reindex(this.registrationId);
    if(req){
      this.$toast.success("Registration Reindexed");
      this.registrationId = null;
    }else{
      this.$toast.error("Something went wrong");
    }
  }
}
